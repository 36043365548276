import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"

import css from "./email.module.scss"

const metadata = {
  title: "ReferralCandy email address verification",
  description: "Failed to verify your email address",
}

// Prevent flash of 404 during page redirect for client-only paths created in gatsby-node
// See: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119
const browser = typeof window !== "undefined" && window

const NotSuccessfullyVerifiedEmail = (props) =>
  browser && (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container}>
        <h2>Your email address is not successfully verified</h2>
        <h3>
          {" "}
          Please contact our{" "}
          <a href="mailto:support@referralcandy.com">support</a>
        </h3>
      </Grid>
    </Layout>
  )

export default NotSuccessfullyVerifiedEmail
